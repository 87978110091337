Vue.asyncComponent('bp-resizable', {
    props: {
        referenceElement: {
            default: () => {
                this.$el.parentElement
            }
        }
    },
    data: () => {
        return {
            resizing: false,
            startX: null,
            startLeft: null,
            startRight: null,
            startWidth: null,
        }
    },
    methods: {
        /**
         * Start resizing set the needed vars so we can calculate the width & offset
         * @param e
         */
        startResize(e) {
            this.$emit('startResize');
            this.resizing = true;
            this.startX = e.clientX;
            this.startWidth = parseInt(this.$refs.resizable.offsetWidth, 10);
            const resizableBoundingClientRect = this.$refs.resizable.getBoundingClientRect();
            const referenceElementBoundingClientRect = this.referenceElement.getBoundingClientRect();
            this.startLeft = Math.round(resizableBoundingClientRect.x - referenceElementBoundingClientRect.x);
            this.startRight = Math.round((referenceElementBoundingClientRect.x + referenceElementBoundingClientRect.width) - (resizableBoundingClientRect.x + resizableBoundingClientRect.width));
        },
        /**
         * Start resizing + add the needed listeners so wa can pick up every drag change
         * @param e
         */
        startResizeRight(e) {
            this.startResize(e);
            document.addEventListener('mousemove', this.resizeFromRight);
            document.addEventListener('mouseup', this.stopResizeFromRight);
        },
        /**
         * Start resizing + add the needed listeners so wa can pick up every drag change
         * @param e
         */
        startResizeLeft(e) {
            this.startResize(e);
            document.addEventListener('mousemove', this.resizeFromLeft);
            document.addEventListener('mouseup', this.stopResizeFromLeft);
        },
        /**
         * Calculate the width when dragging from right
         * @param e
         */
        resizeFromRight(e) {
            const width = (this.startWidth + e.clientX - this.startX);
            const right = this.startRight + (this.startX - e.clientX);

            this.$emit('resize', {width: width, offsetLeft: this.startLeft, offsetRight: right});
        },
        /**
         * Calculate the width & offset left when dragging from left
         * @param e
         */
        resizeFromLeft(e) {
            let left = this.startLeft + (e.clientX - this.startX);
            const width = (this.startWidth - e.clientX + this.startX);

            this.$emit('resize', {width: width, offsetLeft: left, offsetRight: this.startRight});
        },
        /**
         * remove the drag event listeners
         */
        stopResizeFromRight() {
            this.resizing = false;
            this.$emit('stopResize');
            document.removeEventListener('mousemove', this.resizeFromRight);
        },
        /**
         * remove the drag event listeners
         */
        stopResizeFromLeft() {
            this.resizing = false;
            this.$emit('stopResize');
            document.removeEventListener('mousemove', this.resizeFromLeft);
        }
    }
}, 'blueprints/utils/resize/bp-resizable.html');