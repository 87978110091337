import errorHandler from './error-handler';
import {generateUrl} from './settings';

const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded'
};

// process a fetch call with the try catch logic & optional inspector
const doFetch = async (url, params, inspector) => {
    try {
        // we will keep the start timestamp so we can calculate the time of a call
        let timestampStart = (new Date()).getTime();

        const request = await fetch(url, params);

        // if something when't wrong
        if ( ! request.ok)  {
            // we throw the error of the request
            throw ({code: request.status, message: request.statusText});
        }

        const response = await request.json();

        if(inspector) {
            // calculate the time of a call and set it to the optional inspector
            inspector.milliseconds = (new Date()).getTime() - timestampStart
        }

        return response;
    } catch (error) {
        errorHandler.handleError(error);
    }
};

export const get = async (path, params, signal, inspector) => {
    const url = generateUrl(path, params);

    return doFetch(url, {
        method: 'GET',
        headers,
        params,
        signal
    }, inspector);
}

export const post = async (path, data, params, signal, inspector) => {
	const url = generateUrl(path, params);
	const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'POST',
        body,
        headers,
        signal
    }, inspector);
}

export const put = async (path, data, params, signal, inspector) => {
	const url = generateUrl(path, params);
    const body = JSON.stringify(data);

    return await doFetch(url, {
        method: 'PUT',
        body,
        headers,
        signal
    }, inspector);
}

const dataManager = {
    install: (Vue) => {
        Vue.prototype.$get = (url, params, inspector) => get(url, params, inspector);
        Vue.prototype.$post = (url, data, params, inspector) => post(url, data, params, inspector);
        Vue.prototype.$put = (url, data, params, inspector) => put(url, data, params, inspector);
        Vue.prototype.$errors = Vue.observable(errorHandler.errors);
    }
}

export default dataManager;