import {post, get} from '../../../spine-appkit-vue/src/utils/data-manager'

export default class dataprovider {
    constructor(id) {
        this.id = id;

        this.getBreakpoints = this.getBreakpoints.bind(this);
        this.getBlocks = this.getBlocks.bind(this);
        this.getBlockTypes = this.getBlockTypes.bind(this);
        this.getOriginalBreakpoint = this.getOriginalBreakpoint.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.getEventListeners = this.getEventListeners.bind(this);
    }

    /**
     * Get block data
     * @returns {[{blocks: [{blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}]}
     */
    getBlocks() {
        if(! this.blocks) {
            this.fetchData()
        }

        return this.blocks;
    }

    async getBlockSettings(id) {
        await this.sleep(1000);
        return {
            appkit: {
                bundleId: 'text',
                definitionId: 'form',
            },
            describer: {
                widgetContextType: 'blueprint-block',
                widget: {
                    id: "reservationSource",
                    type: "ak-link",
                    slot: [],
                    attributes: {
                        "title": "Text 2",
                        "href": "/#/blueprints/text/form/E8vJ8",
                    },
                    callbacks: [
                        'click'
                    ]
                }
            },
            values: {
                title: 'Text 1'
            }
        };
    }

    /**
     * Get the possible block types
     * @returns {[{minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}]}
     */
    async getBlockTypes() {
        if(! this.blockTypes) {
            this.fetchData()
            await this.sleep(1000);
        }
        return this.blockTypes;
    }

    /**
     * Get all breakpoint data
     * @returns {[{isOriginal: boolean, blockPositioning, title: string}, {isOriginal: boolean, blockPositioning, title: string}, {isOriginal: boolean, blockPositioning, title: string}]}
     */
    getBreakpoints() {
        if(! this.breakpoints) {
            this.fetchData()
        }

        return this.breakpoints;
    }

    /**
     * Get the original breakpoint
     * @returns {{isOriginal: boolean, blockPositioning, title: string}}
     */
    getOriginalBreakpoint() {
        return this.getBreakpoints().find( breakpoint => breakpoint.isOriginal === true);
    }

    /**
     * We construct the data for a new block here
     * @param type
     * @returns {{colspan: number, minColspan, offset: number, blocks: *[], areChildrenAllowed, id: *, title: string, titleDefault, requiredParentType: (string|*)}}
     */
    getNewBlockByType(type) {
        return {
            id: Date.now(),
            title: '',
            titleDefault: type.title,
            type: type.type,
            minColspan: type.minColspan,
            colspan: 12,
            offset: 0,
            areChildrenAllowed: type.areChildrenAllowed,
            requiredParentType: type.requiredParentType,
            blocks: [],

        }
    }

    /**
     * This is the default blockpositioning for a new block
     * @param id
     * @returns {{blockId, colspan: number, orderIndexOriginal: number, fullWidth: boolean, offset: number, orderIndexDiff: number, orderIndex: number, isEndOfRow: boolean, isHidden: boolean}}
     */
    getBlockPositioningForNewBlock(id) {
        return {
            blockId: id,
            orderIndex: 0,
            orderIndexOriginal: 0,
            orderIndexDiff: 0,
            colspan: 12,
            offset: 0,
            isEndOfRow: false,
            isHidden: false,
            fullWidth: false,
        }
    }

    /**
     * Get skin settings
     */
    getSkinSettings() {
        return {
            objectId: 1,
            appkit: {
                bundleId: 'skin',
                definitionId: 'form',
            },
            values: {
                title: 'skin 1'
            }

        }
    }

    /**
     * Get blueprint settings
     */
    getBlueprintSettings() {
        return {
            objectId: 1,
            appkit: {
                bundleId: 'blueprint',
                definitionId: 'form',
            },
            values: {
                title: 'blueprint 1'
            }
        }
    }

    /**
     * Get the posible breakpoints
     * @returns {[{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},null,null]}
     */
    fetchBreakpointOptions() {
        return [
            {
                id: "desktop",
                title: "Desktop",
                description: "1400 x 1024",
            },
            {
                id: "latop",
                title: "Laptop",
                description: "1152 x 700",
            },
            {
                id: "tablet-portrait",
                title: "Tablet portrait",
                description: "834 x 1194 (iPad Pro 11\")",
            },
            {
                id: "tablet",
                title: "Tablet landscape",
                description: "1194 x 834 (iPad Pro 11\")",
            },
            {
                id: "phone-landscape",
                title: "Phone landscape",
                description: "812 x 375 (iPad 13 Mini)",
            },
            {
                id: "phone",
                title: "Phone portrait",
                description: "375 x 812 (iPad 13 Mini)",
            },
            {
                id: "print",
                title: "Print",
                description: "How printing this page will look",
            },
        ];
    }

    /**
     * Get the possible conditions for this breakpoint or this block in breakpoint
     * @param breakpoint
     * @param block
     * @returns {[{actionForm: {bundleId: string, definitionId: string}, name: string, description: string},{actionForm: {bundleId: string, definitionId: string}, name: string, description: string},{actionForm: {bundleId: string, definitionId: string}, name: string, description: string}]}
     */
    fetchConditions(breakpoint, block) {
        return [
            {
                name : "Is Authenticated as CMS user?",
                description : "description here",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            },
            {
                name : "user has press user",
                description : "...",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            },
            {
                name : "Device is mobile",
                description : "...",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            }
        ]
    }

    /**
     * Temp function to simulate a sleep function
     * @todo remove this
     */
    sleep(ms) {
        return new Promise(
                resolve => setTimeout(resolve, ms)
        );
    }

    /**
     * Get all the needed data from the backend
     * @param id
     */
    async fetchData() {
        await this.sleep(1000);

        this.blocks = [
            {
                id: 0,
                title: null,
                titleDefault: 'Area',
                type: 'area',
                minColspan: 1,
                areChildrenAllowed: true,
                blocks: [
                    {
                        id: 1,
                        title: null,
                        titleDefault: 'Text',
                        type:'text',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'text',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "Text 1",
                                        "href": "/#/blueprints/text/form/E8vJ8",
                                    },
                                    callbacks: [
                                        'click'
                                    ]
                                }
                            },
                            values: {
                                title: 'Text 1'
                            }
                        },
                    },
                    {
                        id: 2,
                        title: null,
                        titleDefault: 'Image',
                        type:'image',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'text',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "text 1",
                                        "href": "/#/blueprints/text/form/E8vJ8",
                                    }
                                }
                            },
                            values: {
                                title: 'Text 1'
                            }
                        },
                    },
                    {
                        id: 3,
                        title: null,
                        titleDefault: 'Image',
                        type:'image',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'text',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "Text 2",
                                        "href": "/#/blueprints/text/form/E8vJ8",
                                    }
                                }
                            },
                            values: {
                                title: 'Text 2'
                            }
                        },
                    },
                    {
                        id: 4,
                        title: null,
                        titleDefault: 'Text',
                        type:'text',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'text',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "Text 2",
                                        "href": "/#/blueprints/text/form/E8vJ8"
                                    }
                                }
                            },
                            values: {
                                title: 'Text 2'
                            }
                        },
                    },
                ]
            },
            {
                id: 5,
                title: null,
                titleDefault: 'Area',
                type: 'area',
                minColspan: 1,
                areChildrenAllowed: true,
                blocks: [
                    {
                        id: 6,
                        title: null,
                        titleDefault: 'Gallery',
                        type:'gallery',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'gallery',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "Gallery 1",
                                        "href": "/#/blueprints/gallery/form/E8vJ8"
                                    }
                                }
                            },
                            values: {
                                title: 'Gallery 1'
                            }
                        },
                    },
                ]
            },
            {
                id: 7,
                title: null,
                titleDefault: 'Area',
                type: 'area',
                minColspan: 1,
                areChildrenAllowed: true,
                blocks: [
                    {
                        id: 8,
                        title: null,
                        titleDefault: 'Gallery',
                        type:'gallery',
                        minColspan: 1,
                        areChildrenAllowed: false,
                        blocks:[],
                        settings: {
                            appkit: {
                                bundleId: 'gallery',
                                definitionId: 'form',
                            },
                            describer: {
                                widgetContextType: 'blueprint-block',
                                widget: {
                                    id: "reservationSource",
                                    type: "ak-link",
                                    slot: [],
                                    attributes: {
                                        "title": "Gallery 2",
                                        "href": "/#/blueprints/gallery/form/E8vJ8"
                                    }
                                }
                            },
                            values: {
                                title: 'Gallery 2'
                            }
                        },
                    },
                ]
            },
        ];

        this.blockTypes = [
            {
                title: 'Area',
                type: 'area',
                icon: 'texture',
                minColspan: 1,
                areChildrenAllowed: true,
                settings: '',
                preview: '<i class="material-icons">texture</i> Area',
            },
            {
                title: 'Text',
                type: 'text',
                icon: 'title',
                minColspan: 1,
                requiredParentType: 'area',
                areChildrenAllowed: false,
                preview: '<i class="material-icons">title</i> Text',
            },
            {
                title: 'Gallery',
                type: 'gallery',
                icon: 'photo_library',
                minColspan: 1,
                requiredParentType: 'area',
                areChildrenAllowed: false,
                preview: '<i class="material-icons">photo_library</i> Gallery',
            },
            {
                title: 'Form',
                type: 'from',
                icon: 'check_box',
                minColspan: 1,
                requiredParentType: 'area',
                areChildrenAllowed: false,
                preview: '<i class="material-icons">check_box</i> Form',
            }
        ];

        this.breakpoints = [
            {
                id: 'desktop',
                title: 'Desktop',
                maxWidth: '100%',
                isOriginal: true,
                blockPositioning: [
                    {
                        blockId: 0,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 1,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 2,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 3,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 4,
                        orderIndex: 3,
                        orderIndexOriginal: 3,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 5,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 4,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 6,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 7,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 2,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 8,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                ]
            },
            {
                id: 'tablet',
                title: 'Tablet',
                maxWidth: '992px',
                isOriginal: false,
                blockPositioning: [
                    {
                        blockId: 0,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 1,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 2,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 3,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 4,
                        orderIndex: 3,
                        orderIndexOriginal: 3,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 5,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 5,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 6,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 7,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 6,
                        offset: 1,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 8,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                ]
            },
            {
                id: 'phone',
                title: 'Phone',
                maxWidth: '576px',
                isOriginal: false,
                blockPositioning: [
                    {
                        blockId: 0,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 1,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 2,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 3,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 4,
                        orderIndex: 3,
                        orderIndexOriginal: 3,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 5,
                        orderIndex: 1,
                        orderIndexOriginal: 1,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 6,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 7,
                        orderIndex: 2,
                        orderIndexOriginal: 2,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                    {
                        blockId: 8,
                        orderIndex: 0,
                        orderIndexOriginal: 0,
                        orderIndexDiff: 0,
                        colspan: 12,
                        offset: 0,
                        isEndOfRow: false,
                        isHidden: false,
                        fullWidth: false,
                    },
                ]
            }
        ];
    }

    /**
     * Get the full preview for the current breakpoint
     * @param breakpoint
     */
    async getPreview(breakpoint) {
        const response  = await get('/../preview-html.html')
        return response.html;
    }


    /**
     * Save the current blueprint
     * @param breakpoints
     * @param blocks
     * @returns {boolean}
     */
    async save( breakpoints, blocks) {
        await this.sleep(2000);

        return {
            success: true,
            todos: [
                {type: "event", eventName: "save"},
                {type: "message", title: "blueprint saved successfully", level: "info", text: "blueprint saved successfully"}
            ]
        };
    }

    /**
     * Return the defined event listeners
     * @returns {{saveButtonClicked: dataprovider.saveButtonClicked, propertyChanged: dataprovider.propertyChanged, save: (function(*, *): boolean), activeBreakpointsChanged: dataprovider.activeBreakpointsChanged, orderChanged: dataprovider.orderChanged}}
     */
    getEventListeners() {
        return {
            saveButtonClicked: this.saveButtonClicked,
            propertyChanged: this.propertyChanged,
            orderChanged: this.orderChanged,
            activeBreakpointsChanged: this.activeBreakpointsChanged,
            save: this.save.bind(this),
        }
    }

    saveButtonClicked( breakpoints, blocks) {
        //console.log('saveButtonClicked');
    }

    propertyChanged( breakpoints, blocks) {
        //console.log('propertyChanged');
    }

    orderChanged( breakpoints, blocks) {
        //console.log('orderChanged');
    }

    activeBreakpointsChanged(newBreakpointIds) {
        //console.log('activeBreakpointsChanged', newBreakpointIds);
    }
}