// import * as types from '../store/types';
import {getSetting} from './settings';

let errors = [];

const sendError = (error) => {
     errors.push(error);
};

export default {
    handleError: (error) => {
        switch (error.code) {
            // case 400:
            //     return sendError('Error 400');
            case 401:
                // redirect to login screen
                if (getSetting('paths.login')) {
                    window.location = getSetting('paths.login');
                }
                return sendError('Error 401');
            // case 404:
            //     return sendError('Error 404');
            // case 500:
            //     return sendError('Oeps something whent wrong: 500');
            default:
                return sendError(error);
        }
    },
    errors: errors
}