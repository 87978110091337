Vue.asyncComponent('bp-hidden-blocks-dropdown', {
    data: function() {
        return {
            showDropdownHidden: false,
        }
    },
    props: {
        breakpoint: {
            type: Object
        }
    },
    computed: {
        hiddenBlocks() {
            return this.getHiddenBlocks(this.breakpoint.blocks);
        }
    },
    methods: {
        /**
         * @param blocks
         * @returns {*[]}
         */
        getHiddenBlocks(blocks) {
            let matches = []
            blocks.forEach(block => {
                if(block.isHidden) {
                    matches.push(block)
                }

                if(block.blocks && block.blocks.length) {
                    matches = [...matches, ...this.getHiddenBlocks(block.blocks)];
                }
            })

            return matches;
        },

        /**
         * @param e
         */
        blockPropertyChanged(e) {
            this.$emit('blockPropertyChanged', e)
        }
    }
}, 'blueprints/editor/bp-hidden-blocks-dropdown.html');