Vue.asyncComponent('bp-tab-navigation-item', {
    props: {
        activeTabId: {
            required: true,
        },
        id: {
            required: true,
        },
        label: {
            required: true,
        }
    },
    model: {
        prop: 'activeTabId',
        event: 'changeTab'
    },
    methods: {
        setActiveTab() {
            this.$emit('changeTab', this.id);
        }
    },
}, 'blueprints/utils/tabs/bp-tab-navigation-item.html');