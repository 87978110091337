const previewHelperMixin = {
    props: {
        previewIframe: {
            type: HTMLIFrameElement,
        },
    },
    methods: {
        getBlockElementById(id) {
            return this.previewIframe.contentWindow.document.querySelector(`[data-block-id="${id}"]`);
        },
    }
};

export default previewHelperMixin;