Vue.asyncComponent('bp-block-options', {
    props: {
        block: {
            type: Object,
        },
        guidesActive: {
            type: Boolean
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('deleteBlock', this.block.id);
            this.close();
        },
        close() {
            this.$emit('close');
        },
        toggleShow() {
            this.$emit('toggleShow')
        },
        toggleFullWidth() {
            this.$emit('toggleFullWidth')
        },
        toggleEndOfRow() {
            this.$emit('toggleEndOfRow')
        },
        toggleGuides() {
            this.$emit('toggleGuides')
        }
    }
}, 'blueprints/editor/bp-block-options.html');