import ActiveDraggableBlockMixin from '../../mixins/ActiveDraggableBlockMixin.js';
import ActiveDropzoneMixin from '../../mixins/ActiveDropzoneMixin.js';
import EditorEventMixin from '../../mixins/EditorEventMixin.js';
import PreviewHelperMixin from "../../mixins/PreviewHelperMixin";

Vue.asyncComponent('bp-dropzone', {
    mixins: [ActiveDraggableBlockMixin, ActiveDropzoneMixin, EditorEventMixin, PreviewHelperMixin],
    data() {
        return {
            resizeBlockId: null
        }
    },
    props: {
        id: {
            required: true,
        },
        previewMode: {
            type:  Boolean,
            required: false,
            default: false
        },
        parentBlockId: {
            type: Number,
            required: false,
        },
        blocks: {
            type: Array,
            required: true,
        },
        forceGuides: {
            type: Boolean,
            default: false
        },
        blocksActiveGuides: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        /**
         * Check if the dropzone is active
         * @returns {boolean}
         */
        dropzoneActive() {
            return this.getActiveDropzone() === this.id;
        },
        /**
         * resizing block
         * @returns {*|null}
         */
        activeResizingColspan() {
            const resizeBlock = this.blocks.find(block => block.id === this.resizeBlockId);

            if(! resizeBlock) {
                return null;
            }

            // we need to check if the blocks before need space
            const indexResizeBlock = this.blocks.indexOf(resizeBlock);
            let totalOffsetBefore = 0;
            // we add up the colspan of the items before
            this.blocks.slice(0, indexResizeBlock).forEach(block => {
                totalOffsetBefore = totalOffsetBefore + block.colspan;
            });

            return (totalOffsetBefore % 12) + resizeBlock.colspan + resizeBlock.offset;
        }
    },
    methods: {
        /**
         * @param e
         */
        onDrop(e) {
            // the drag event has ended we reset the active block & drop Zone
            this.setActiveBlock(null);
            this.resetActiveDropzone();
        },
        /**
         * Get the current dragged block and change the position of it in the blocks array
         * @param e
         */
        onDragEnter(e) {
            let activeBlock = this.activeBlock;
            // check if the items is meant for this dropzone
            if (this.enterdCurrentDropzone(e) && activeBlock) {
                const orderIndex = this.getOrderIndexForItem(e);

                // add the item at the correct offset position
                this.blockParentChanged({parentBlockId: this.parentBlockId, orderIndex, blockId: activeBlock.id})
            }
        },
        /**
         * @param e
         */
        onDragOver(e) {
            // check if we are over the current dropzone
            if (this.enterdCurrentDropzone(e)) {
                // if so set it as the active dropzone so we can show de grid
                this.setCurrentDropzoneActive();
            }
        },
        startResizeChildBlock(id) {
            this.resizeBlockId =  id;
            this.setCurrentDropzoneActive();
        },
        stopResizeChildBlock() {
            this.resizeBlockId = null;
            this.resetActiveDropzone();
        },
        /**
         * Set the current dropzone as the active one
         */
        setCurrentDropzoneActive() {
            this.setActiveDropzone(this.id);
        },
        /**
         * Remove the active dropzone
         */
        resetActiveDropzone() {
            this.$emit('stopResize');
            this.setActiveDropzone(null);
        },
        /**
         * Check if the first dropzone in the events path is our dropzone
         * @param e
         * @returns {boolean}
         */
        enterdCurrentDropzone(e) {
            // we get the firstdropzone element defined in the path of the event
            const firstDropzone = e.path.find(item => {
                return item.dataset && "dropzone" in item.dataset;
            });

            // check if the first dropzone is our dropzone
            return firstDropzone === this.$refs.dropzone;
        },
        /**
         * Determine the orderIndex of the item by the Y postion
         *
         * @param e
         * @param list
         * @param item
         * @returns {number|*}
         */
        getOrderIndexForItem(e) {
            // when we don't have any blocks yet we start at 0
            if (! this.blocks) {
                return 0;
            }

            // no we will check where in the list of blocks the new item needs to fit in
            for (let i = 0; i < this.blocks.length; i += 1) {
                const itemId = this.blocks[i].id;

                // we check the offsetY element of each item
                const $item = this.$refs['drag-' + itemId][0].$el.getBoundingClientRect();
                const itemY = $item.top + ($item.height/2);
                const itemX = $item.left + ($item.width/2);

                // if the dragged item offsetY is smaller we need to add it before this item
                if(e.clientY < (itemY - 5)) {
                    return i;
                }
                if((e.clientY > (itemY - 5) && e.clientY < (itemY + 5)) && e.clientX < itemX) {
                    return i;
                }
                if((e.clientX > (itemX - 5) && e.clientX < (itemX + 5)) && e.clientY > itemY) {
                    return i;
                }
                if(e.clientX < (itemX - 5) && e.clientY < (itemY + 5)) {
                    return i;
                }
                // if the dragged item offsetY is bigger dan we go on to the next one
            }

            // if we come here the item's offset Y is bigger then any other of the list
            return this.blocks.length;
        },
        /**
         * Notify the parent that a block dimesnions has changed
         * If the block is present in the current dropzone we check if there are any items before it.
         * If this is the case we take the spaceBefore in to account for the offset left
         * @param colspan
         * @param offset
         * @param blockId
         */
        blockDimensionsChanged({colspan, offset, blockId}) {
            // get the current block
            let block = this.blocks.find(block => block.id === blockId);

            // if the block isen't present in the current dropzone continue
            if(! block) {
                this.$emit('blockDimensionsChanged', {colspan, offset, blockId});
                return;
            }

            // check if we need to set the full offsetFrom left or not
            // depending if there is another bock before this one in the same row
            let usedSpace = 0;
            const blocksBefore = this.blocks.slice(0, this.blocks.indexOf(block))

            blocksBefore.forEach((block) => {
                usedSpace += block.colspan + block.offset;
            });

            const leftOverSpace = usedSpace % 12;

            // check if there are blocks before
            if((leftOverSpace +  block.colspan + block.offset) <= 12) {
                // if so subtract the space used by the element before from the calculated offset
                offset = (offset - leftOverSpace) > 0 ?
                        offset - leftOverSpace :
                        0;
            }

            this.$emit('blockDimensionsChanged', {colspan, offset, blockId});
        },
        /**
         * A block has been dragged and the parent or orderIndex has changed
         * @param $event
         */
        blockParentChanged($event) {
            this.$emit('blockParentChanged', $event);
        },
    }
}, 'blueprints/editor/bp-dropzone.html');