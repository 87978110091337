Vue.asyncComponent('bp-block-type', {
    data() {
        return {
            isActive: false,
        }
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        preview: {
            type: String,
            required: false,
        },
        areChildrenAllowed: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        startDrag(e) {
            this.isActive = true;
            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';

            this.$emit('dragstart');
        },
        endDrag() {
            this.isActive = false;
            this.$emit('dragend');
        }
    }
}, 'blueprints/editor/bp-block-type.html');