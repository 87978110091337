export const getSetting = (setting) => {
    const subKeys = setting.split('.');
    let result = appKitSettings;
    
    for (let i = 0; i < subKeys.length; i++) {
        const element = subKeys[i];
        if (result[element] !== undefined) {
            result = result[element];
        }
        else {
            result = null;
            break;
        }
    }
    return result;
}

// generate an url based on the path and params provided
export const generateUrl = (path, params) => {
    let url;

    // check if the path is a full url or not
    if(path.substring(0,4) !== 'http') {
        // if not we add the basepath the the current path
        url = new URL(getSetting('paths.baseAppKit')  + path);
    } else {
        url = new URL(path);
    }

    if(params) {
        // add the params if there are any
        Object.keys(params).forEach((key) => {
            if (Array.isArray(params[key])) {
                params[key].forEach(element => url.searchParams.append(`${key}\[\]`, element));
            } else url.searchParams.append(key, params[key]);
        })
    }

    return url.toString();
}

export const settingsPlugin = {
    install: (Vue) => {
        Vue.prototype.$getSetting = (key) => getSetting(key);
        Vue.prototype.$assetUrl = () => getSetting('paths.assets');
        Vue.prototype.$appKitUrl = () => getSetting('paths.baseAppKit');
        Vue.prototype.$generateUrl = (path, params) => generateUrl(path, params);
    }
}
