Vue.asyncComponent('bp-dropdown', {
    props: {
        label: {
            type: String,
            required: false
        },
        show: {
            type: Boolean,
            required: false,
            default: false
        },
        position: {
            type: String,
            required: false,
            default: 'left'
        }
    },
    model: {
        prop: 'show',
        event: 'input'
    },
    methods: {
        toggleDropdown() {
            this.$emit('input', !this.show);
        },
        hideDropdown() {
            this.$emit('input', false);
        },
    }
}, 'blueprints/utils/dropdown/bp-dropdown.html');