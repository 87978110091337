import DataProvider from '../providers/Dataprovider';
import BlueprintDataTranslator from '../helpers/BlueprintDataTranslator';

Vue.asyncComponent('bp-blueprint', {
    data: () => {
        return {
            loading: false,
            dataProvider: null,
            breakpoints: [],
            blocks: [],
            originalBreakpoint: null,
            activeBreakpointId: null,
            dragActive: false,
            activeBlock: null,
            activeDropzone: null,
            showBreakpointDropdown: false,
            showDropdownBlockTypes: false,
            conditionsBreakpoint: null,
            conditionsBlock: null,
            previewMode: false,
            blueprintSettings: null,
            skinSettings: null,
            showGuides: null,
            activeOptionsBlock: null,
            tempParentBlock: null,
            blocksActiveGuides: [],
        }
    },
    props: {
        viewMode: {
            type: String,
        }
    },
    watch: {
        activeBlock() {
            // when we stop dragging we reset the temp parent block
            if ( ! this.activeBlock) {
                this.tempParentBlock = null;
                this.activeDropzone = null;
            }
        }
    },
    computed: {
        breakpointThreeStructure() {
            return (new BlueprintDataTranslator(this.breakpoints, this.blocks)).translate();
        }
    },
    methods: {
        /**
         * add a  breakpoint
         * @param breakpointToAdd
         */
        addBreakpoint(breakpointToAdd, index) {
            breakpointToAdd.blockPositioning = this.originalBreakpoint.blockPositioning;
            this.breakpoints.splice(index,0,breakpointToAdd)
        },
        /**
         * remave a breakpoint
         * @param breakpointToRemove
         */
        removeBreakpoint(breakpointToRemove) {
            const indexToRemove = this.breakpoints.findIndex(breakpoint => breakpoint.id === breakpointToRemove.id);
            this.breakpoints.splice(indexToRemove, 1);
        },
        /**
         * Activate a dropzone
         * @param e
         */
        setActiveDropzone(e) {
            this.activeDropzone = e;
        },
        /**
         * The active block type has changes
         * @param type
         */
        activeBlockTypeChanged(block) {
            // whe, we reset the active block type don't show dropdown any more
            if (! block) {
                this.showDropdownBlockTypes = false;
            }

            this.activeBlock = block;
        },
        /**
         * Add a new block to the current blocks array
         * @param type
         */
        addNewBlock(newBlock) {
            //first we add the new block type to the (active)blocks
            this.blocks.push(newBlock);

            // now we need to add a position element for the new block in each breakpoint
            this.breakpoints.forEach((breakpoint) => {
                breakpoint.blockPositioning.push(this.dataProvider.getBlockPositioningForNewBlock(newBlock.id))
            });
        },
        /**
         * delete a block in the tree structure
         * @param blocks
         * @param id
         */
        deleteBlock(blocks, id) {
            for (let i = 0; i < blocks.length; i += 1) {
                if (blocks[i].id === id) {
                    blocks.splice(i, 1);
                } else {
                    this.deleteBlock(blocks[i].blocks, id)
                }
            }
        },
        /**
         * Set the colspan & offset for the current block in the correct breakpoint
         * @param colspan
         * @param offset
         * @param blockId
         * @param breakpointId
         */
        blockDimensionsChanged({colspan, offset, blockId}, breakpointId) {
            // find the breakpoint where we need to do the position changes
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);

            // find the block postioning element for the current block
            const positioning = breakpoint.blockPositioning.find(blockPositioning => blockPositioning.blockId === blockId);
            positioning.colspan = colspan;
            positioning.offset = offset;
        },
        /**
         * Set the parent for the current block and change the orderIndex for the block in the current breakpoint
         * @param parentBlockId
         * @param orderIndex
         * @param blockId
         * @param breakpointId
         */
        async blockParentChanged({parentBlockId, orderIndex, blockId}, breakpointId) {
            // find the breakpoint where we need to do the position changes
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);

            // make a copy of the needed block without any reference
            const block = {...this.getBlock(this.blocks, blockId)};

            let parent = this;
            if (parentBlockId !== undefined) {
                parent = this.getBlock(this.blocks, parentBlockId);
            }

            // When we have a temp parent but the block has changed to an existing parent we will need to remove the temp parent
            if(block.requiredParentType && (parent.type === block.requiredParentType) && this.tempParentBlock && parent !== this.tempParentBlock) {
                // get the parent of the tempParent
                let oldParentOfTemp = this.getParent(this, this.tempParentBlock.id);

                // delete the temp parent
                this.deleteBlock(this.blocks, this.tempParentBlock.id);

                // create new indexes for the parent of the oldParent
                oldParentOfTemp.blocks.forEach((oldSiblingBlocks, order) => {
                    this.setOrderIndexForBlock( order, oldSiblingBlocks, breakpoint);
                });

                this.tempParentBlock = null;
            }

            // If we have a requiredParentType and it doesn't match we need to wrap the element in it first
            // also if there is already a temporary parent block we will move that element instead
            if (block.requiredParentType && (parent === this || parent.type !== block.requiredParentType) && !this.tempParentBlock) {
                // create the requiredParent
                const newParentBlock = await this.getRequiredParentBlock(block.requiredParentType);
                // we set it as the temperary parent until we drop the element
                this.tempParentBlock = newParentBlock;
                // add the new parent
                this.addNewBlock(newParentBlock);
            }

            // when there is a temporary parent we need to move the parent the order index is given and add the block in it
            if (block.requiredParentType && (parent === this || parent.type !== block.requiredParentType) && this.tempParentBlock) {
                // delete the block from the old parent
                this.deleteBlock(this.blocks, this.tempParentBlock.id);
                parent.blocks.splice(orderIndex, 0, this.tempParentBlock);

                // now we need to update the order index of all the sibling blocks
                parent.blocks.forEach((siblingBlocks, order) => {
                    this.setOrderIndexForBlock( order, siblingBlocks, breakpoint);
                });

                orderIndex = 0;
                parent = this.tempParentBlock;
            }

            // because we move the element from another one we will need to update the old parent order indexes ass well
            let oldParent = this.getParent(this, blockId);

            // delete the block from the old parent
            this.deleteBlock(this.blocks, blockId);

            // ad the block to the new parent in the correct location
            parent.blocks.splice(orderIndex, 0, block);

            // now we need to update the order index of all the sibling blocks
            parent.blocks.forEach((siblingBlocks, order) => {
                this.setOrderIndexForBlock( order, siblingBlocks, breakpoint);
            });

            if (oldParent) {
                // update the old parent order indexes ass well
                oldParent.blocks.forEach((oldSiblingBlocks, order) => {
                    this.setOrderIndexForBlock( order, oldSiblingBlocks, breakpoint);
                });
            }
        },
        async getRequiredParentBlock(type) {
            const blockTypes = await this.dataProvider.getBlockTypes();
            const parentBlockType = blockTypes.find(blockType => blockType.type === type);
            return this.dataProvider.getNewBlockByType(parentBlockType);
        },
        /**
         * Set the order index for a specific block.
         * We do this by updating the block positioning in the current breakpoint
         * @param orderIndex
         * @param block
         * @param breakpoint
         */
        setOrderIndexForBlock(orderIndex, block, breakpoint) {
            const positioning = breakpoint.blockPositioning.find(blockPositioning => blockPositioning.blockId === block.id);

            positioning.orderIndex = orderIndex;

            this.triggerDataProviderEvent('orderChanged');
            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * Update the property
         * We do this by updating the block positioning in the current breakpoint
         * The blueprintDataTranslator will handle the rest
         * @param $event
         * @param breakpointId
         */
        setBlockPositioningProperty(blockId, properties, breakpointId) {
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);
            const positioning = breakpoint.blockPositioning.find(blockPositioning => blockPositioning.blockId === blockId);

            // we will set all the properties
            Object.keys(properties).forEach(key => {
                positioning[key] = properties[key];
            });

            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * @param properties
         * @param breakpointId
         */
        blockPropertyChanged(properties, breakpointId) {
            const blockId = properties.blockId;
            const block = this.getBlock(this.blocks, blockId);
            delete properties['blockId'];

            Object.keys(properties).forEach(key => {
                block[key] = properties[key];
            });

            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * Check if the dataprovider has an event listiner for the current event
         * if so trigger it
         * @param $eventName
         */
        async triggerDataProviderEvent($eventName) {
            const $callback = this.dataProvider.getEventListeners()[$eventName];

            if($callback) {
                return await $callback(this.breakpoints, this.blocks);
            }
        },
        /**
         * We show the condition form for the specific breakpoint
         * @param $breakpoint
         */
        showConditionsForBreakpoint(breakpoint) {
            this.$router.push({name: 'blueprint-condition-form', params: {breakpointId: breakpoint.id}});
        },
        /**
         *
         */
        async saveBlueprint() {
            this.loading = true;
            const result = await this.triggerDataProviderEvent('save');
            this.loading = false;
            this.$handleActions(result.todos, this);
        },
        /**
         * Activate or deactive the preview mode
         * @param previewMode bool
         */
        setPreviewMode(previewMode) {
            this.previewMode = previewMode;
        },
        /**
         * Open the blueprint settings in a stacked window
         */
        editBlueprintSettings() {
            this.$router.push({name:'blueprint-form', params: {
                bundle: this.blueprintSettings.bundleId,
                definition: this.blueprintSettings.definitionId,
                objectId: this.blueprintSettings.objectId,
            }});
        },
        /**
         * Open the skin settings form in a stacked window
         */
        editSkinSettings() {
            this.$router.push({name:'blueprint-form', params: {
                bundle: this.skinSettings.bundleId,
                definition: this.skinSettings.definitionId,
                objectId: this.skinSettings.objectId,
            }});
        },
        /**
         * Toggle guides of root level
         */
        toggleGuides() {
            this.showGuides = ! this.showGuides;
        },
        /**
         * Show the options modal for the blockId given
         * @param blockId
         * @param breakpointId
         */
        showBlockOptions(blockId, breakpointId) {
            const blockPositioning = this.getBlockPositioning(blockId, breakpointId);
            const block = this.getBlock(this.blocks, blockId);

            this.activeOptionsBlock = {...blockPositioning, ...block};
        },
        /**
         * We hide the options modal
         */
        hideBlockOptions() {
            this.activeOptionsBlock = null;
        },
        /**
         * Toggle the isHidden property of the active block
         * @param breakpointId
         */
        activeOptionsBlockToggleShow(breakpointId) {
            this.setBlockPositioningProperty(this.activeOptionsBlock.id, {
                isHidden: !this.getBlockPositioning(this.activeOptionsBlock.id, breakpointId).isHidden,
            }, breakpointId);
        },
        /**
         * Toggle the full width property of the active block
         * @param breakpointId
         */
        activeOptionsBlockToggleFullWidth(breakpointId) {
            this.blockDimensionsChanged({colspan: 12, offset: 0, blockId: this.activeOptionsBlock.id}, breakpointId);
            this.setBlockPositioningProperty(this.activeOptionsBlock.id, {
                fullWidth: !this.getBlockPositioning(this.activeOptionsBlock.id, breakpointId).fullWidth,
            }, breakpointId);
        },
        /**
         * Toggle the end of row property of the active block
         * @param breakpointId
         */
        activeOptionsBlockToggleEndOfRow(breakpointId) {
            this.setBlockPositioningProperty(this.activeOptionsBlock.id, {
                isEndOfRow: !this.getBlockPositioning(this.activeOptionsBlock.id, breakpointId).isEndOfRow,
            }, breakpointId);
        },
        /**
         * Wee keep an array where we will store wicht blocks need to show there guides
         * @param breakpointId
         */
        activeOptionsBlockToggleGuides(breakpointId) {
            const activeBlockIndex = this.blocksActiveGuides.indexOf(this.activeOptionsBlock.id);

            if (activeBlockIndex !== -1) {
                this.blocksActiveGuides.splice(activeBlockIndex, 1);

            } else {
                this.blocksActiveGuides.push(this.activeOptionsBlock.id);
            }
        },
        /**
         * Get the block positioning for the given blockId and breakpointId
         * @param blockId
         * @param breakpointId
         * @returns {*}
         */
        getBlockPositioning(blockId, breakpointId) {
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);
            return breakpoint.blockPositioning.find(blockPositioning => blockPositioning.blockId === blockId);
        },
        /**
         * @param blocks
         * @param id
         * @returns {null}
         */
        getBlock(blocks, id) {
            let result = null;

            // get the block by id recursive
            blocks.forEach(block => {
                if(block.id === id) {
                    result = block;
                }

                if(block.blocks.length) {
                    let childResult = this.getBlock(block.blocks, id);
                    if(childResult) {
                        result = childResult;
                    }
                }
            });

            return result;
        },
        /**
         * @param parent
         * @param childId
         * @returns {null}
         */
        getParent(parent, childId) {
            let result = null;

            // get the block by id recursive
            parent.blocks.forEach(block => {
                if(block.id === childId) {
                    result = parent;
                }

                if(block.blocks.length) {
                    let childResult = this.getParent(block, childId);
                    if(childResult) {
                        result = childResult;
                    }
                }
            });

            return result;
        },
        async saveDataObject($event) {
            this.loading = true;
            await this.dataProvider.getBlockSettings();
            this.loading = false;
        }
    },
    async created() {
        this.dataProvider = new DataProvider('page');
        this.loading = true;
        await this.dataProvider.fetchData();
        this.loading = false;

        this.breakpoints = this.dataProvider.getBreakpoints();
        this.blocks = this.dataProvider.getBlocks();
        this.skinSettings = this.dataProvider.getSkinSettings();
        this.blueprintSettings = this.dataProvider.getBlueprintSettings();
        this.originalBreakpoint = this.dataProvider.getOriginalBreakpoint();

        // set the original breakpoint as the active breakpoint
        this.activeBreakpointId = this.originalBreakpoint.id;
    }
}, 'blueprints/bp-blueprint.html');