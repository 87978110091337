const editorEventMixin = {
    methods: {
        /**
         * Show block options for a certain block
         * @param $event
         */
        showBlockOptions($event) {
            this.$emit('showBlockOptions', $event)
        },
        /**
         * A child block has been dragged and its position and/or orderIndex has changed
         * @param $event
         */
        blockParentChanged($event) {
            this.$emit('blockParentChanged', $event);
        },
        /**
         * A child block dimensions has changed notify the parents
         * @param $event
         */
        blockDimensionsChanged($event) {
            this.$emit('blockDimensionsChanged', $event)
        },
        /**
         * A prop of a child or current block has changed
         * @param e
         */
        blockPropertyChanged(e) {
            this.$emit('blockPropertyChanged', e);
        },
    }
};

export default editorEventMixin;