Vue.asyncComponent('bp-colspan-resizable', {
    data: () => {
        return {
            parentElement: null
        }
    },
    props: {
        colspan: {
            type: Number,
            required: true,
        },
        offset: {
            type: Number,
            required: false
        },
        maxCols: {
            type: Number,
            required: false
        },
        minColspan: {
            type: Number,
            default: 1,
            required: false
        },
        previewMode: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        /**
         *
         * @param width
         * @param offsetLeft
         * @param offsetRight
         */
        resize ({width, offsetLeft, offsetRight}) {
            const widthPercentage = (width / this.parentWidth());
            const offsetLeftPercentage = (offsetLeft / this.parentWidth());
            const offsetRightPercentage = (offsetRight / this.parentWidth());

            let colspan = this.calculateColspan(widthPercentage);
            let colspanOffsetLeft = this.calculateOffset(offsetLeftPercentage);
            const colspanOffsetRight = this.calculateOffset(offsetRightPercentage);

            // check if the colspan & offset value's are always the maxCols
            // this is needed because in some edge cases this isn't the case
            if((colspan + colspanOffsetLeft + colspanOffsetRight)  > this.maxCols) {
                // if we are over the maxCols we subtract the remaining col(s) from the width colspan
                colspan = colspan - ((colspanOffsetLeft + colspan + colspanOffsetRight) - this.maxCols);
            }

            if((colspan + colspanOffsetLeft + colspanOffsetRight)  < this.maxCols) {
                // if we are under the maxCols we add the missing col(s) from the width colspan
                colspan = colspan + (this.maxCols - (colspanOffsetLeft + colspan + colspanOffsetRight));
            }

            // not smaller then the min cols
            if (colspan <= this.minColspan) {
                colspan = this.minColspan;
            }

            this.$emit('changeDimensions', {colspan: colspan, offset: colspanOffsetLeft});
        },
        /**
         * Calculate the colspan by the given offset, maxCols and minColspan
         * @param percentage
         */
        calculateColspan(percentage) {
            let colspan = Math.round(percentage * this.maxCols);

            return colspan;
        },
        /**
         * Calculate the Offset in colspan by the given offset and maxCols
         * @param percentage
         */
        calculateOffset(percentage) {
            let offset = Math.round(percentage * this.maxCols);

            // the offset can not go below 0
            if(offset < 0) {
                offset = 0;
            }

            // the offset can not be bigger than maxCols - 1
            if(offset >= this.maxCols) {
                offset =this.maxCols - 1;
            }

            return offset;
        },
        /**
         * Remove the offset and add the current offset to the width so we still use the same space
         */
        removeOffset() {
            this.$emit('changeDimensions', {colspan: this.colspan + this.offset, offset: 0});
        },
        /**
         * Get the parentWidth so we can use this as reference to calculate with
         * @returns {number}
         */
        parentWidth() {
            // get the parent width but subtract the gutter width
            return this.parentElement.offsetWidth - 24;
        }
    },
    mounted() {
        this.parentElement = this.$el.parentElement;
    }
}, 'blueprints/utils/resize/bp-colspan-resizable.html');