Vue.asyncComponent('bp-breakpoint-list', {
    data() {
        return {
            activeBreakpointIds: [],
            breakpointOptions: [],
        }
    },
    props: {
        breakpoints: {
            type: Array,
            required: true
        },
        originalBreakpoint: {
            type: Object,
            required: true
        },
        dataProvider: {
            type: Object,
            required: true
        }
    },
    methods: {
        activeBreakpointsChanged($event, option) {
            if (this.activeBreakpointIds.includes(option.id)) {
                this.$emit('addBreakpoint', option, this.breakpointOptions.indexOf(option));
            } else {
                this.$emit('removeBreakpoint', option);
            }
        }
    },
    created() {
        // get the conditions for the current breakpoint
        this.breakpointOptions = this.dataProvider.fetchBreakpointOptions();
        // get the curren breakpoint for display purposes
        this.activeBreakpointIds = this.breakpoints.map(breakpoint => breakpoint.id);
    }
}, 'blueprints/editor/bp-breakpoint-list.html');